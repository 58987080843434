import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import lv1_1 from '../../assets/images/level/1_1.jpg';
import lv1_2 from '../../assets/images/level/1_2.jpg';
import lv1_3 from '../../assets/images/level/1_3.jpg';
import lv1_4 from '../../assets/images/level/1_4.jpg';
import lv2_1 from '../../assets/images/level/2_1.jpg';
import lv2_2 from '../../assets/images/level/2_2.jpg';
import lv2_3 from '../../assets/images/level/2_3.jpg';
import lv2_4 from '../../assets/images/level/2_4.jpg';
import lv3_1 from '../../assets/images/level/3_1.jpg';
import lv3_2 from '../../assets/images/level/3_2.jpg';
import lv3_3 from '../../assets/images/level/3_3.jpg';
import lv3_4 from '../../assets/images/level/3_4.jpg';
import lv4_1 from '../../assets/images/level/4_1.jpg';
import lv4_2 from '../../assets/images/level/4_2.jpg';
import lv4_3 from '../../assets/images/level/4_3.jpg';
import lv4_4 from '../../assets/images/level/4_4.jpg';

const img = {
    vip1: [lv1_1, lv1_2, lv1_3, lv1_4],
    vip2: [lv2_1, lv2_2, lv2_3, lv2_4],
    vip3: [lv3_1, lv3_2, lv3_3, lv3_4],
    vip4: [lv4_1, lv4_2, lv4_3, lv4_4],
};

export default function Level({ vip }) {
    return (
        <Carousel
            autoPlay={true}
            showArrows={false}
            showStatus={false}
            dynamicHeight={true}
            emulateTouch={true}
            infiniteLoop={true}
            interval={2500}
            showIndicators={false}
            showThumbs={false}
            swipeable={true}
        >
            {img[vip].map((img, i) => {
                return (
                    <div key={i}>
                        <img src={img} alt="banner" className="w-52 h-40" />
                    </div>
                );
            })}
        </Carousel>
    );
}
