import React, { useEffect, useRef, useState } from 'react';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const customStyle = {
    visibility: {
        desktop: {
            xOffset: '0',
            yOffset: '60',
            position: 'br',
        },

        mobile: {
            xOffset: 0,
            yOffset: 60,
            position: 'br',
        },
    },
};

function Service() {
    const [isLoading, setIsLoading] = useState(true);

    // const talkTo = useRef();

    // useEffect(() => {
    //     toast.success('Vui lòng đợi chút, trong thời gian chúng tôi kết nối với CSKH Target Corporation !', {
    //         theme: 'light',
    //     });

    //     setTimeout(() => {
    //         setIsLoading(false);
    //         talkTo.current.maximize();
    //     }, 5000);
    // }, []);

    // useEffect(() => {
    //     return () => {
    //         talkTo.current.hideWidget();
    //     };
    // }, []);

    useEffect(() => {
        window.open('https://livechat123.vip/chat/b909b9af-526a-48be-970b-a1a0443c53e3');

        // return () => {
        //     console.log(talkTo.current);
        // };
    });

    // function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
    //     console.log('LiveChatWidget.onNewEvent', event);
    // }

    return (
        <>
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <ReactLoading type="bubbles" color="#ff8a00" height={10} width={70} />
                </div>
            )}

            {/* <LiveChatWidget license="18430581" visibility="maximized" /> */}

            {/* <TawkMessengerReact
                propertyId="65f9a076a0c6737bd122824c"
                widgetId="1hpbhlksk"
                ref={talkTo}
                customStyle={customStyle}
            /> */}
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default Service;
